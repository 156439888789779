.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #101121;
  min-height: calc(100vh - 53px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.App-link {
  display: block;
  border: none;
  background-color: #89C97D;
  color: #f6f6f6;
  padding: 15px 30px;
  text-align: center;
  font-size: 18px;
  margin: 0 auto;
  cursor: pointer;
  transition: 0.3s;
  text-decoration: none;
}
.App-footer p {
  min-width: 230px;
}
.App-link:hover {
  background-color: #62A87C;
  box-shadow: 10px 10px 0 0 #000;
}
.App-footer {
  color: #353535;
}
.App-email {
  color: #62A87C;
}

@media screen and (max-width: 800px) {
  .App-footer {
    flex-direction: column-reverse;
  }
  .App-header {
    min-height: calc(100vh - 159px);
  }
}